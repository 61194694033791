import {mapActions, mapGetters} from "vuex";

export default {
  methods : {
    ...mapActions([
      'getUserRanksKeys'
    ])
  },
  computed : {
    ...mapGetters([
      'userRanksKeys'
    ])
  },
  async created() {
    await this.getUserRanksKeys();
  }
}
