<template>
  <div class="row">
    <div class="col-sm-9">
      <h3 class="text-left">User Settings</h3>
    </div>
    <div class="col-sm-3 text-right">
      <button class="e-btn e-btn-green e-text-white pr-3 mt-2" data-toggle="modal" data-target="#NewUser">
        <font-awesome-icon icon="plus-square" style="width: 25px"/>
        <small class="font-weight-bold">
          NEW USER
        </small>
      </button>
    </div>

    <div class="col-12 pl-0 text-center col-lg-12">
      <div class="row no-gutters">
        <nav aria-label="..." class="col-8">
          <ul class="pagination text-smallest mb-2 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setPage(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item"
                :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setPage(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setPage(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
        <div class="col-4">
          <input type="text" name="searchUser" v-model="filters.search" class="form-control form-control-sm" id="" placeholder="Search User">
        </div>
        <table class="table table-sm table-bordered small">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">FIRST NAME</th>
            <th scope="col">LAST NAME</th>
            <th scope="col">EMAIL</th>
            <th scope="col">USERNAME</th>
            <th scope="col">ROLE</th>
            <th scope="col">ACTION</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="loading">
            <td colspan="100%">
              <loading></loading>
            </td>
          </tr>
          <template v-else>
            <tr v-for="(user, index) in allUsers" :key="user.id">
              <th scope="row">{{ index + pagination.from }}</th>
              <td>{{ user.first_name }}</td>
              <td>{{ user.last_name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.username }}</td>
              <td :style="'background-color: ' + getUserRole(user, true) + '; font-weight: bold'">{{ getUserRole(user) }}</td>
              <td class="text-white">
                <div class="btn-group dropleft">
                  <button
                    id="actionDropdown"
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="mr-2 btn btn-sm btn-secondary force-white-all"
                    data-toggle="dropdown"
                  >
                    <font-awesome-icon icon="cogs"/>
                  </button>
                  <div aria-labelledby="actionDropdown" class="dropdown-menu">
                    <button class="dropdown-item text-secondary font-weight-bolder"
                            data-toggle="modal" data-target="#UpdateCurrency"
                            @click="$router.push({name: 'SettingsUserProfile', params: {id: user.id}})">
                      <font-awesome-icon icon="eye"/>
                      View
                    </button>
                    <button class="dropdown-item text-info font-weight-bolder" data-toggle="modal" data-target="#UpdateCurrency"
                            v-if="isElite()"
                            @click="impersonateUser(user)">
                      <font-awesome-icon icon="user"/>
                      Impersonate
                    </button>
                    <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                            @click="deleteUser(user.id)"
                    >
                      <font-awesome-icon icon="trash" class="force-danger-all"/>
                      Delete
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
      <new-user @resetPage="setPage"/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import AppLoading from "@/components/elements/AppLoading";
import {UserService} from "@/services/UserService";
import {AlertService} from "@/services/AlertService";
import NewUser from '@/components/settings/user/NewUser'
import {permissionMixin} from "@/mixins/permissionMixins";
import userMixin from "@/mixins/UserMixin";
import _ from 'lodash';

export default {
  name: "SettingsUsers",
  data() {
    return {
      loading: false,
      filters: {
        page: 1,
        search: null,
      },
    }
  },
  components: {
    'loading': AppLoading,
    'new-user': NewUser,
  },
  mixins: [PaginationMixin, permissionMixin, userMixin],
  methods: {
    ...mapActions(['getAllUsers']),
    setPage(page) {
      this.pagination.currentPage = page;
      this.filters.page = page
      this.getAllUsers(this.filters);
    },
    async deleteUser(id) {
      if (await AlertService.confirmDeleteAlert()) {
        const message = await UserService.deleteUser(id);

        if (message) {
          AlertService.successAlert('User has been deleted successfully', 'Deleting User');

          this.loading = true
          this.setPage();
          this.loading = false
        } else {
          AlertService.errorAlert("There's an error deleting user", 'Deleting User');
        }
      }
    },
  },
  computed: {
    ...mapGetters(['allUsers']),
  },
  created() {
    this.loading = true
    this.setUsers = _.debounce(this.setPage, 750);
    this.setPage();
    this.loading = false
  },
  watch: {
    "filters.search"() {
      this.setUsers();
    }
  }
}
</script>

<style scoped>
table th, table td {
  vertical-align: middle !important;
}
</style>
